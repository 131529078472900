//LOCALHOST
// const API_BASE_URL = 'http://localhost:5001/api';

//LIVE SERVER
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const LoginApi = async ({ phoneNumber, password }) => {
  // console.log(phoneNumber, password);
  try {
    const response = await fetch(`${API_BASE_URL}/users/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        phoneNumber,
        password,
      }),
    });

    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return null;
    }
  } catch (error) {
    console.error("Failed to Login:", error);
    throw error;
  }
};

export const RegisterApi = async ({
  name,
  phoneNumber,
  email,
  password,
  dateOfBirth,
  gender,
  deviceToken,
}) => {
  console.log('RegisterApi params',name, phoneNumber, email, password, dateOfBirth, gender);
  const credit = 3600;
  const isActive = true;
  const isGPN = false;
  const helped = 0;
  const talkHours = 0;
  try {
    const response = await fetch(`${API_BASE_URL}/users/register`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name,
        phoneNumber,
        email,
        password,
        dateOfBirth,
        credit,
        gender,
        isActive,
        isGPN,
        helped,
        talkHours,
        deviceToken,
      }),
    });

    if (response.ok) {
      const data = await response.json();
      return data;
      
    } else {
      return null;
    }
  } catch (error) {
    console.log('RegisterApi Error', error);
    throw error;
  }
};

export const AgoraStartApi = async () => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  if (!userData) {
    return null;
  }
  const userId = userData.userId;
  const token = userData.token;
  // console.log('UserId:',userId,'Token:',token);
  try {
    const response = await fetch(
      `${API_BASE_URL}/agora/start-call?userId=${userId}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
    );

    // console.log(response);

    if (response.ok) {
      const data = await response.json();
      // console.log("AgoraStartApi response",data);
      return data;
    } else {
      return null;
    }
  } catch (e) {
    console.log('AgoraStartApi Failed', e);
  }
};

export const AgoraStartVideoApi = async () => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  if (!userData) {
    return null;
  }
  const userId = userData.userId;
  const token = userData.token;
  const callType = true;
  // console.log('UserId:',userId,'Token:',token);
  try {
    const response = await fetch(
      `${API_BASE_URL}/agora/start-videocall`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          userId,
          callType,
        }),
      },
    );

    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return null;
    }
  } catch (e) {
    console.log('AgoraStartVideoApi Failed', e);
  }
};

export const AgoraEndApi = async ({time,isVideo}) => {
  console.log('AgoraEndApi time:', time,'isVideo',isVideo);
  const userData = JSON.parse(localStorage.getItem("userData"));
  if (!userData) {
    return null;
  }
  const userId = userData.userId;
  const token = userData.token;
  try {
    const response = await fetch(`${API_BASE_URL}/agora/end-call`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        userId,
        time,
        isVideo
      }),
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return null;
    }
  } catch (e) {
    console.log('AgoraEndApi Failed', e);
  }
};

// export const UpdateUserData = async data => {
//   try {
//     const response = await fetch(`${API_BASE_URL}/users/${userId}`, {
//       method: 'PUT',
//       headers: {
//         'Content-Type': 'application/json',
//         Authorization: `Bearer ${token}`,
//       },
//       body: JSON.stringify(data),
//     });
//     if (response.ok) {
//       const data = await response.json();
//       return data;
//     } else {
//       return null;
//     }
//   } catch (e) {
//     console.log('UpdateUserData Failed', e);
//   }
// };

// export const DeleteUser = async () => {
//   try {
//     const response = await fetch(`${API_BASE_URL}/users/${userId}`, {
//       method: 'DELETE',
//       headers: {
//         'Content-Type': 'application/json',
//         Authorization: `Bearer ${token}`,
//       },
//     });
//     if (response.ok) {
//       const data = await response.json();
//       return data;
//     } else {
//       return null;
//     }
//   } catch (e) {
//     console.log('UpdateUserData Failed', e);
//   }
// };

export const GetFriendDetailsApi = async (channelId) => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  if (!userData) return null;
  const token = userData.token;
  try {
    const response = await fetch(
      `${API_BASE_URL}/agora/start-call?channelId=${channelId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return null;
    }
  } catch (e) {
    console.log("GetFriendDetails Failed", e);
  }
};

export const GetFriendRequestListApi = async () => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  if (!userData) {
    return null;
  }
  const userId = userData.userId;
  const token = userData.token;
  try {
    const response = await fetch(
      `${API_BASE_URL}/friend-requests/friendrequest/${userId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return null;
    }
  } catch (e) {
    console.log("GetFriendRequestListApi Failed", e);
  }
};

export const GetFriendListApi = async () => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  if (!userData) return null;
  const userId = userData.userId;
  const token = userData.token;

  console.log("GetFriendListApi is working", userId);
  try {
    const response = await fetch(
      `${API_BASE_URL}/friend-requests/friendlist/${userId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return null;
    }
  } catch (e) {
    console.log("GetFriendListApi Failed", e);
  }
};

export const SendFriendRequestApi = async ({ senderId, receiverId }) => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  if (!userData) return null;
  const token = userData.token;
  console.log("SendFriendRequestApi is working", senderId, receiverId);
  try {
    const response = await fetch(`${API_BASE_URL}/friend-requests`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        senderId,
        receiverId,
      }),
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return null;
    }
  } catch (e) {
    console.log("SendFriendRequestApi Failed", e);
  }
};

export const AcceptFriendRequestApi = async (tableId) => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  if (!userData) return null;
  const token = userData.token;
  // console.log("AcceptFriendRequestApi is working with Table Id", tableId);
  try {
    const response = await fetch(
      `${API_BASE_URL}/friend-requests/${tableId}/accept`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return null;
    }
  } catch (e) {
    console.log("AcceptFriendRequestApi Failed", e);
  }
};

export const DeleteFriendRequestApi = async (tableId) => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  if (!userData) return null;
  const token = userData.token;
  console.log("DeleteFriendRequestApi is working", tableId);
  try {
    const response = await fetch(`${API_BASE_URL}/friend-requests/${tableId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return null;
    }
  } catch (e) {
    console.log("DeleteFriendRequestApi Failed", e);
  }
};

export const CallFriendApi = async ({ senderId, receiverId }) => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  if (!userData) return null;
  const token = userData.token;
  console.log("CallFriendApi is working", senderId, receiverId);
  try {
    const response = await fetch(`${API_BASE_URL}/agora/start-callWithFriend`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        senderId,
        receiverId,
      }),
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return null;
    }
  } catch (e) {
    console.log("CallFriendApi Failed", e);
  }
};

// // Implementation not done
// export const AddQuotesApi = async ({senderId,receiverId}) => {
//   console.log('CallFriendApi is working', senderId,receiverId);
//   const token = await GetToken();
//   try {
//     const response = await fetch(
//       `${API_BASE_URL}/agora/start-callWithFriend`,
//       {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//           Authorization: `Bearer ${token}`,
//         },
//         body: JSON.stringify({
//           senderId,
//           receiverId
//         }),
//       },
//     );
//     if (response.ok) {
//       const data = await response.json();
//       return data;
//     } else {
//       return null;
//     }
//   } catch (e) {
//     console.log('CallFriendApi Failed', e);
//   }
// };

export const RazorPayOrderIdApi = async ({amount,orderType}) => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  if (!userData) {
    return null;
  }
  const userId = userData.userId;
  const token = userData.token;
  console.log('RazorPayOrderIdApi is working with amount:', amount,'userId:',userId);
  
  // const orderType = 'AudioTalkTime';
  try {
    const response = await fetch(`${API_BASE_URL}/order/create-order`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        amount,
        userId,
        orderType,
      }),
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return null;
    }
  } catch (e) {
    console.log('RazorPayOrderIdApi Failed', e);
  }
};

export const RazorPayPaymentStatusApi = async ({
  paymentId,
  signature,
  orderId,
}) => {
  console.log(
    'RazorPayPaymentStatusApi is working with paymentId:',
    paymentId,
    'signature',
    signature,
    'orderId',
    orderId,
  );
  const userData = JSON.parse(localStorage.getItem("userData"));
  if (!userData) {
    return null;
  }
  const token = userData.token;
  try {
    const response = await fetch(`${API_BASE_URL}/order/verify`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        paymentId,
        orderId,
        signature,
      }),
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return null;
    }
  } catch (e) {
    console.log('RazorPayPaymentStatusApi Failed', e);
  }
};

export const LocationSendApi = async ({
  address,
  latitude,
  longitude,
  isDefault,
}) => {
  console.log(
    "LocationSendApi params",
    address,
    latitude,
    longitude,
    isDefault
  );
  const userData = JSON.parse(localStorage.getItem("userData"));
  if (!userData) {
    return null;
  }
  const userId = userData.userId;
  const token = userData.token;
  try {
    const response = await fetch(`${API_BASE_URL}/addresses`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        userId,
        address,
        latitude,
        longitude,
        isDefault,
      }),
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return null;
    }
  } catch (e) {
    console.log("LocationSendApi Failed", e);
  }
};

export const LocationListApi = async () => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  if (!userData) {
    return null;
  }
  const userId = userData.userId;
  const token = userData.token;
  try {
    const response = await fetch(`${API_BASE_URL}/addresses/user/${userId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return null;
    }
  } catch (e) {
    console.log("LocationSendApi Failed", e);
  }
};

export const ImageSendApi = async ({ file }) => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  if (!userData) {
    return null;
  }
  const userId = userData.userId;
  const token = userData.token;
  try {
    const response = await fetch(
      `${API_BASE_URL}/users/${userId}/uploadImage`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          file,
        }),
      }
    );
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return null;
    }
  } catch (e) {
    console.log("LocationSendApi Failed", e);
  }
};

export const UpdateApi = async (data) => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  if (!userData) {
    return null;
  }
  const userId = userData.userId;
  const token = userData.token;
  try {
    const response = await fetch(`${API_BASE_URL}/users/${userId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return null;
    }
  } catch (e) {
    console.log("UpdateApi error", e);
  }
};

export const DefaultAddressApi = async ({ id }) => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  if (!userData) {
    return null;
  }
  const userId = userData.userId;
  const token = userData.token;
  const isDefault = true;
  try {
    const response = await fetch(`${API_BASE_URL}/addresses/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        isDefault,
      }),
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return null;
    }
  } catch (e) {
    console.log("DefaultAddressApi error", e);
  }
};

export const DeleteAddressApi = async ({ id }) => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  if (!userData) {
    return null;
  }
  const userId = userData.userId;
  const token = userData.token;
  try {
    const response = await fetch(`${API_BASE_URL}/addresses/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return null;
    }
  } catch (e) {
    console.log("DefaultAddressApi error", e);
  }
};

export const GetReferralCode = async () => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  if (!userData) {
    return null;
  }
  const userId = userData.userId;
  const token = userData.token;
  try {
    const response = await fetch(`${API_BASE_URL}/referrals/${userId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return null;
    }
  } catch (e) {
    console.log("GetReferralCode error", e);
  }
};

export const ForgotPassApi = async ({ number, password }) => {
  console.log("ForgotPassApi params:", number, password);
  try {
    const response = await fetch(
      `${API_BASE_URL}/users/phoneNumber/${number}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          password,
        }),
      }
    );
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return null;
    }
  } catch (e) {
    console.log("GetReferralCode error", e);
  }
};

export const PutReferralApi = async ({ referralCode }) => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  if (!userData) {
    return null;
  }
  const userId = userData.userId;
  const token = userData.token;
  console.log("PutReferralApi Params:", userId, referralCode, token);
  try {
    const response = await fetch(`${API_BASE_URL}/referrals/${userId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        referralCode,
      }),
    });

    if (response.ok) {
      const data = await response.json();

      return data;
    } else {
      return null;
    }
  } catch (e) {
    console.log("PutReferralApi error", e);
  }
};

export const UserValidateApi = async (data) => {
  console.log("UserValidateApi params:", data);
  try {
    const response = await fetch(`${API_BASE_URL}/users/validate`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    // console.log('UserValidateApi response :',response)
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return null;
    }
  } catch (e) {
    console.log("UserValidateApi error", e);
  }
};

export const UserTalktimeApi = async () => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  if (!userData) {
    return null;
  }
  const userId = userData.userId;
  const token = userData.token;
  try {
    const response = await fetch(`${API_BASE_URL}/users/${userId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return null;
    }
  } catch (e) {
    console.log("UserTalktimeApi Failed", e);
  }
};


export const DeleteAccountApi = async ({phoneNumber,password}) => {
  console.log("DeleteAccountApi params:", phoneNumber,password);
  const userData = JSON.parse(localStorage.getItem("userData"));
  if (!userData) {
    return null;
  }
  const token = userData.token;
  try {
    const response = await fetch(`${API_BASE_URL}/users/verifyUserForAccountDeletion`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        phoneNumber,
        password
      }),
    });
    if(!response.ok){
       console.log("the response of the api is not okay ")
       throw new Error("Something went wrong!");
    }
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return null;
    }
  } catch (e) {
    console.log("DeleteAccountApi error", e);
  }
};

export const ConfirmDeleteAccount= async({data}) =>{
  console.log("the value from the api is ",data)
  const userData = JSON.parse(localStorage.getItem("userData"));
  if (!userData) {
    return null;
  }
  const token = userData.token;
  try {
    const response = await fetch(`${API_BASE_URL}/users/delete/${data}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        // Authorization: `Bearer ${token}`,
      },
      // body: JSON.stringify({
      //   phoneNumber,
      //   password
      // }),
    });
    if(!response.ok){
      console.log("Some thing went wrong while fetching the api ");
    }
    if (response.ok) {
      
      return true;
    } else {
      return false;
    }
  } catch (e) {
   
    console.log("DeleteAccountApi error");
    return false;
  }

};

export const RegisterPsychologistApi = async ({
  name,
  email,
  phoneNumber,
  password,
  gender,
  dob,
  address,
  education,
  profession,
  skills,
  employment,
  about,
}) => {
  const educationalDocs = 'path/to/educational/docs.pdf';
  const industryCertificates = 'path/to/certificates.pdf';
  const audioSample = 'path/to/audio/sample.mp3';
  const videoSample = 'path/to/video/sample.mp4';
  const profilePicture = 'path/to/profile/picture.jpg';
  const whyChooseMe = [{option: 'Experienced'}, {option: 'Certified'}];
  const languages = [{name:"English"},{name:"Spanish"}];
  const availableFor = [{option:'Audio'},{option:'Video'}]
  const data = {
    name,
    email,
    phoneNumber,
    password,
    gender,
    dob,
    address,
    education,
    profession,
    skills,
    employment,
    about,
    availableFor,
    languages,
    educationalDocs,
    industryCertificates,
    audioSample,
    videoSample,
    profilePicture,
    whyChooseMe,
  };
  console.log('Register Api params:', data);
  try {
    const response = await fetch(`${API_BASE_URL}/psychologists/register`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return null;
    }
  } catch (error) {
    console.log('Failed to fetch RegisterPsychologistApi:', error);
    throw error;
  }
};