import React, { useEffect, useState } from "react";
import { RegisterPsychologistApi } from "../services/Api";
import Header from "../components/Header";
import { useNavigate } from "react-router-dom";

function PsychologistSignupPage() {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [gender, setGender] = useState("Male");
  const [dob, setDob] = useState("");
  const [address, setAddress] = useState("");
  const [education, setEducation] = useState("");
  const [profession, setProfession] = useState("");
  const [skills, setSkills] = useState("");
  const [employment, setEmployment] = useState();
  const [about, setAbout] = useState("");
  const [availableFor, setAvailableFor] = useState("");
  const [error, setError] = useState("");

  const [files, setFiles] = useState({
    audio: null,
    video: null,
    educationalDocs: null,
    industryCertificates: null,
  });

  const handleFileChange = (event, type) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFiles((prevFiles) => ({
        ...prevFiles,
        [type]: selectedFile,
      }));
      console.log(`Selected ${type} file:`, selectedFile);
    }
  };

  const validateInputs = () => {
    if (!name.trim()) {
      setError("Name cannot be empty.");
      return false;
    }
    if (!/^\d{10}$/.test(phoneNumber)) {
      setError("Phone number must be 10 digits.");
      return false;
    }
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      setError("Enter a valid email address.");
      return false;
    }
    if (!dob) {
      setError("Please select your Date of Birth.");
      return false;
    }
    if (!password) {
      setError("Enter Password");
      return false;
    }
    if (!address) {
      setError("Enter Address");
      return false;
    }
    if (!education) {
      setError("Enter Education");
      return false;
    }
    if (!profession) {
      setError("Enter Profession");
      return false;
    }
    if (!skills) {
      setError("Enter Skills");
      return false;
    }
    if (!about) {
      setError("Enter About");
      return false;
    }
    if (!employment) {
      setError("Enter Employment");
      return false;
    }

    const today = new Date();
    const selectedDate = new Date(dob);
    if (selectedDate >= today) {
      setError("Date of Birth cannot be today or in the future.");
      return false;
    }
    if (!gender) {
      setError("Please select your Gender.");
      return false;
    }
    // Clear error if all validations pass
    setError("");
    return true;
  };

  const handleSignUp = async () => {
    if (!validateInputs()) {
      console.log("If Statement");
      return;
    } else {
      const response = await RegisterPsychologistApi({
        name,
        email,
        phoneNumber,
        password,
        gender,
        dob,
        address,
        education,
        profession,
        skills,
        employment,
        about,
      });
      alert("Successfully registered as Psychologist!");
      console.log("RegisterPsychologistApi response:", response);
      navigate("/");
    }
  };

  return (
    <>
      <Header />
      <div className="PsychologistSignupPage-container">
        <input
          className="input-field"
          placeholder="Enter your Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <input
          className="input-field"
          type="tel"
          placeholder="Enter your Phone Number"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
          maxLength={10}
        />
        <input
          className="input-field"
          type="email"
          placeholder="Enter your email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          className="input-field"
          type="password"
          placeholder="Enter your password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <input
          className="input-field"
          placeholder="Enter your Address"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
        />
        <input
          className="input-field"
          placeholder="Enter your Education"
          value={education}
          onChange={(e) => setEducation(e.target.value)}
        />
        <input
          className="input-field"
          placeholder="Enter your Profession"
          value={profession}
          onChange={(e) => setProfession(e.target.value)}
        />
        <input
          className="input-field"
          placeholder="Enter your Skills"
          value={skills}
          onChange={(e) => setSkills(e.target.value)}
        />
        <input
          className="input-field"
          placeholder="Enter your Employment"
          value={employment}
          onChange={(e) => setEmployment(e.target.value)}
        />
        <input
          className="input-field"
          placeholder="About"
          value={about}
          onChange={(e) => setAbout(e.target.value)}
        />
        <input
          className="input-field"
          type="date"
          value={dob}
          onChange={(e) => setDob(e.target.value)}
          placeholder="Select your Date of Birth"
        />
        <h2>Mail all the documents to operations@emomatrix.com</h2>
        <h4 style={{margin:0,fontWeight:'500'}}>Audio Sample(30 sec)</h4>
        <input
          className="input-field"
          type="file"
          accept=".mp3,.wav,.aac"
        />
        <h4 style={{margin:0,fontWeight:'500'}}>Video Sample(30 sec)</h4>
        <input
          className="input-field"
          type="file"
          accept=".mp4,.mov,.avi"
        />
        <h4 style={{margin:0,fontWeight:'500'}}>Educational docs</h4>
        <input
          className="input-field"
          type="file"
          accept=".pdf,.doc,.docx"
        />
        <h4 style={{margin:0,fontWeight:'500'}}>Industry Certificates</h4>
        <input
          className="input-field"
          type="file"
          accept=".pdf,.doc,.docx"
        />
        <div className="gender-selection">
          <label>
            <input
              type="radio"
              value="Male"
              checked={gender === "Male"}
              onChange={(e) => setGender(e.target.value)}
            />
            Male
          </label>
          <label>
            <input
              type="radio"
              value="Female"
              checked={gender === "Female"}
              onChange={(e) => setGender(e.target.value)}
            />
            Female
          </label>
          <label>
            <input
              type="radio"
              value="Other"
              checked={gender === "Other"}
              onChange={(e) => setGender(e.target.value)}
            />
            Other
          </label>
        </div>
        {error && <p className="error-text">{error}</p>}
        <button className="submit-button" onClick={handleSignUp}>
          <h2 className="submit-text">Register</h2>
        </button>
      </div>
    </>
  );
}

export default PsychologistSignupPage;
