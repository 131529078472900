
import React from 'react'
import styles from '../styles/PrivacyPolicyPsychologist.module.css';
const PrivacyPolicyPsychologist = () => {
  return (
    // <div>PrivacyPolicyPsychologist
    //      <div className={styles.contentContainer}>
    //      <h1 className={styles.centeredTitle}>Privacy Policy</h1>
    //      <p>Welcome to Good People, a social friendship app that helps users connect through audio/video calls, 
    //         home visits, and psychologist consultations. We value your privacy and are committed to protecting your personal data.</p>
    //         <p>This Privacy Policy explains how we collect, use, store, and protect your information
    //              while using our services. By signing up for Good People, you agree to this policy.</p>

    //      <h3>1. Who We Are</h3>

    //     </div>
    // </div>
    <div className={styles.contentContainer}>
    <div className="w-full min-h-screen bg-gray-50 p-4 md:p-8">
    <div className="max-w-4xl mx-auto bg-white rounded-xl shadow-md p-4 md:p-8">
      <h1 className={`text-2xl md:text-3xl text-gray-900 mb-2 text-center font-bold ${styles.centeredTitle}` }>
        Privacy Policy – Good People Social Friendship App
      </h1>
      {/* <p className="text-gray-600 text-center mb-8 italic">
        Last Updated: February 12, 2025
      </p>
       */}
      <div className="mb-8 leading-relaxed">
        <p>Welcome to Good People, a social friendship app that helps users connect
             through audio/video calls, home visits, and psychologist consultations.
              We value your privacy and are committed to protecting your personal data.</p>
        <p>This Privacy Policy explains how we collect, use, store, and 
            protect your information while using our services. By signing up for Good People, you agree to this policy.</p>
      </div>

      <section className="mb-10">
        <h2 className="text-xl md:text-2xl text-gray-800 mb-4 pb-2 border-b-2 border-gray-200 font-semibold">
          1. Who We Are
        </h2>
        <p className="leading-relaxed">
        "Good People" is owned and operated from India and complies 
        with applicable Indian laws, including the Information Technology Act, 2000, and GDPR guidelines for international users.
        </p>
        <b>We only collect and process the information that users provide while using our app.</b>
        
        
      </section>

      <section className="mb-10">
        <h2 className="text-xl md:text-2xl text-gray-800 mb-4 pb-2 border-b-2 border-gray-200 font-semibold">
          2. Information We Collect
        </h2>
        <p>We collect only the information you provide while using the app. This includes:</p>
        <h3 className="text-lg text-gray-700 mt-4 mb-3 font-medium">
          2.1 Information You Provide
        </h3>
        <ul className="space-y-3 pl-6">
          <li className="relative pl-6 before:content-['✅'] before:absolute before:left-0">
           <b> Account Details: </b>Name, phone number, email, and profile information.
          </li>
          <li className="relative pl-6 before:content-['✅'] before:absolute before:left-0">
            <b>Verification Documents:</b> ID proof (if required for verification).
          </li>
          <li className="relative pl-6 before:content-['✅'] before:absolute before:left-0">
            <b>Financial Details:</b> Psychologist's bank details for payments and withdrawals.
          </li>
          <li className="relative pl-6 before:content-['✅'] before:absolute before:left-0">
            <b>Interaction Data:</b> Call logs, timestamps, and audio/video call durations.
          </li>
        </ul>

        <h3 className="text-lg text-gray-700 mt-6 mb-3 font-medium">
          2.2 Information We Collect Automatically
        </h3>
        <ul className="space-y-3 pl-6">
          <li className="relative pl-6 before:content-['✅'] before:absolute before:left-0">
            <b>Device Information:</b> IP address, device model, OS version, and app usage data.
          </li>
          <li className="relative pl-6 before:content-['✅'] before:absolute before:left-0">
            <b>Usage Data:</b> Features used, session length, and interaction patterns.
          </li>
          <li className="relative pl-6 before:content-['✅'] before:absolute before:left-0">
            <b>Location Data:</b> Only if you allow, for coordinating home visits.
          </li>
        </ul>
      </section>

      <section className="mb-10">
        <h2 className="text-xl md:text-2xl text-gray-800 mb-4 pb-2 border-b-2 border-gray-200 font-semibold">
          3. How We Use Your Information
        </h2>
        <p>We use your data only for the services you request, including:</p>
        <ul className="space-y-3 pl-6">
          <li className="relative pl-6 before:content-['✅'] before:absolute before:left-0">
           <b> Providing Services – </b>Enabling calls, home visits, and consultations.
          </li>
          <li className="relative pl-6 before:content-['✅'] before:absolute before:left-0">
            <b>Account Verification – </b>Checking user identity for security purposes.
          </li>
          <li className="relative pl-6 before:content-['✅'] before:absolute before:left-0">
            <b>Processing Payments –</b> Managing transactions securely.
          </li>
          <li className="relative pl-6 before:content-['✅'] before:absolute before:left-0">
            <b>Improving the App –</b> Analyzing trends to enhance user experience.
          </li>
          <li className="relative pl-6 before:content-['✅'] before:absolute before:left-0">
           <b> Ensuring Security –</b> Preventing fraud and unauthorized access.
          </li>
        </ul>
        <p className="bg-gray-50 p-4 rounded-lg border-l-4 border-blue-500 mt-4 font-medium">
          We do NOT sell your personal data to third parties.
        </p>
      </section>
      <section className="mb-10">
        <h2 className="text-xl md:text-2xl text-gray-800 mb-4 pb-2 border-b-2 border-gray-200 font-semibold">
           4. Data Sharing & Third Parties
        </h2>
        <p>We only share necessary information in the following cases:</p>
        <ul className="space-y-3 pl-6">
          <li className="relative pl-6 before:content-['✅'] before:absolute before:left-0">
           <b>  Service Providers:  </b>Payment processors, cloud storage, and analytics tools.
          </li>
          <li className="relative pl-6 before:content-['✅'] before:absolute before:left-0">
            <b>Legal Compliance: </b>Checking user identity for security purposes.
          </li>
          <li className="relative pl-6 before:content-['✅'] before:absolute before:left-0">
            <b>Psychologists & Professionals</b> Only limited details necessary for consultations.
          </li>
         
        </ul>
        <p className="bg-gray-50 p-4 rounded-lg border-l-4 border-blue-500 mt-4 font-medium">
        Your data is never sold, rented, or misused.
        </p>
      </section>
      <section className="mb-10">
        <h2 className="text-xl md:text-2xl text-gray-800 mb-4 pb-2 border-b-2 border-gray-200 font-semibold">
        5. How We Protect Your Data
        </h2>
        <p>We use industry-standard security measures to protect your information:</p>
        <ul className="space-y-3 pl-6">
          <li className="relative pl-6 before:content-['✅'] before:absolute before:left-0">
           <b>   Encryption:  </b>SSL/TLS encryption for data transmission.
          </li>
          <li className="relative pl-6 before:content-['✅'] before:absolute before:left-0">
            <b>Restricted Access </b>Only authorized personnel can access sensitive data.
          </li>
          <li className="relative pl-6 before:content-['✅'] before:absolute before:left-0">
            <b> Regular Security Audits: </b> We conduct periodic reviews to ensure safety.
          </li>
         
        </ul>
        <p className="bg-gray-50 p-4 rounded-lg border-l-4 border-blue-500 mt-4 font-medium">
        Despite our efforts, no system is 100% secure, so we recommend users take precautions while sharing information online.
        </p>
      </section>
      <section className="mb-10">
        <h2 className="text-xl md:text-2xl text-gray-800 mb-4 pb-2 border-b-2 border-gray-200 font-semibold">
        6. Your Rights & Choices
        </h2>
        <p>You have full control over your data, including:</p>
        <ul className="space-y-3 pl-6">
          <li className="relative pl-6 before:content-['✅'] before:absolute before:left-0">
           <b>   Access & Correction:   </b>Update your information in Settings {'>'}  Profile.
          </li>
          <li className="relative pl-6 before:content-['✅'] before:absolute before:left-0">
            <b>Data Deletion: </b>You can delete your account (transaction data may be retained for legal reasons).
          </li>
          <li className="relative pl-6 before:content-['✅'] before:absolute before:left-0">
            <b> Opt-Out:  </b> Disable location sharing and marketing messages anytime.
          </li>
         
        </ul>
        <p className="bg-gray-50 p-4 rounded-lg border-l-4 border-blue-500 mt-4 font-medium">
        For any data requests, email us at privacy@goodpeopleapp.com.
        </p>
      </section>
      <section className="mb-10">
        <h2 className="text-xl md:text-2xl text-gray-800 mb-4 pb-2 border-b-2 border-gray-200 font-semibold">
        7. Children’s Privacy
        </h2>
        <p>Good People does not allow users under the age of 13. If we detect a minor’s account, we will delete their data immediately.</p>
        
      </section>
      <section className="mb-10">
        <h2 className="text-xl md:text-2xl text-gray-800 mb-4 pb-2 border-b-2 border-gray-200 font-semibold">
        8. Policy Updates
        </h2>
        <p>We may update this Privacy Policy from time to time. If there are major changes, we will notify users via:</p>
        <div className="bg-gray-50 p-4 rounded-lg mt-4">
            <ul>
                <li><b>In-App Notification</b><br></br></li>
                <li><b>Email Alerts</b></li>
            </ul>
          
          
          <p>Continued use of the app means acceptance of the updated policy.</p>
        </div>
      </section>
      <section className="mb-10">
        <h2 className="text-xl md:text-2xl text-gray-800 mb-4 pb-2 border-b-2 border-gray-200 font-semibold">
          9. Contact Us
        </h2>
        <div className="bg-gray-50 p-4 rounded-lg mt-4">
          <p>📧 Email: privacy@goodpeopleapp.com</p>
          <p>🏢 Address: [Your Company Address, India]</p>
        </div>
      </section>
      <section className="mb-10">
        <h2 className="text-xl md:text-2xl text-gray-800 mb-4 pb-2 border-b-2 border-gray-200 font-semibold">
        10. Compliance & Legal Information
        </h2>
        <p>This policy follows:</p>
        <div className="bg-gray-50 p-4 rounded-lg mt-4">
            <ul>
                <li><b>Indian IT Act, 2000</b><br></br></li>
                <li><b>GDPR (For International Users)</b></li>
                <li><b>Google Play & App Store Privacy Guidelines</b></li>

            </ul>
          
          
          <p>For a detailed breakdown of third-party services like payment gateways, visit our in-app Privacy Hub.</p>
        </div>
      </section>
      <div className="mt-12 text-center pt-8 border-t-2 border-gray-200">
        <p className="bg-gray-50 p-4 rounded-lg border-l-4 border-blue-500 inline-block font-medium">
          🔹 Your Privacy, Your Control!
        </p>
        <p className="mt-4">
          We only collect what's necessary and never misuse your data. Thank you for trusting Good People!
        </p>
      </div>
      
    </div>
  </div>
  </div>
  )
}

export default PrivacyPolicyPsychologist;