// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import { getAuth, RecaptchaVerifier } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBURqDcjapBwoJJpnwJGHHDF4bkRxeTWHE",
  authDomain: "goodpeople-a6614.firebaseapp.com",
  projectId: "goodpeople-a6614",
  storageBucket: "goodpeople-a6614.firebasestorage.app",
  messagingSenderId: "431445338904",
  appId: "1:431445338904:web:6b3a731e8d84c24e124c6d",
  measurementId: "G-HWXYE1N6W8"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const messaging = getMessaging(app);

export const auth = getAuth(app);

export const GenerateToken = async () => {
  try {
    const response = await Notification.requestPermission();
    console.log("Notification permission response:", response);
    if (response === "granted") {
      const token = await getToken(messaging, {
        vapidKey:
          "BGSUW9nhOUq4URmSvpEkMESQPM47xRy6elsroJ4s-wjwTdb8hahhlifw69lKPFS36upYbEoTys4XQkgH5gifckY",
      });
      console.log("Token", token);
      return token;
    } else {
      return;
    }
  } catch (e) {
    console.log(
      "Error during notification permission firebase token generation",
      e
    );
  }
};

// AUTHENTICATION


export const setupRecaptcha = (auth) => {
  if (!window.recaptchaVerifier) {
    window.recaptchaVerifier = new RecaptchaVerifier(auth,
      "recaptcha-container", // This ID should match the <div> in your HTML.
      {
        size: "invisible", // Use 'normal' if you want to show the reCAPTCHA widget.
        callback: (response) => {
          console.log("reCAPTCHA solved:", response);
        },
        "expired-callback": () => {
          console.log("reCAPTCHA expired. Please refresh.");
        },
      },
      
    );
  }
};
