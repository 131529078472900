import Header from "../components/Header";
import Footer from "../components/Footer";
import styles from "./../styles/DeleteAccount.module.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { DeleteAccountApi } from "../services/Api";

const DeleteAccount = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();



  const handleDeleteAccount = async(e)=>{
    e.preventDefault();
    if(phoneNumber === '' || password === ''){
      return;
    }
    const response = await DeleteAccountApi({phoneNumber,password});
    console.log('DeleteAccountApi response',response)
    if (typeof response === "undefined") {
      console.error("Response is undefined");
      return;
    }
    if (response.code == "200") {
      // const data = await response.json();
      console.log("the response",response.data);
      navigate("/deleteAccountConfirmation", {
              state: {
                success: true,
                message: "Account successfully deleted",
                data: response.data, // Pass any data from the response if needed
              },
            });
      
    }
  }
  // const handleDeleteAccount = async (e) => {
  //   e.preventDefault();

  //   try {
  //     console.log(
  //       "the phone number retrived from the form is ",
  //       phoneNumber,
  //       "and the password got from the form is ",
  //       password
  //     );
  //     const response = await fetch(
  //       "http://localhost:5001/api/users/verifyUserForAccountDeletion",
  //       {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization:
  //             "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiI0ODYyNDU3OTM1IiwiaWF0IjoxNzM3OTgxMjYzLCJleHAiOjE3MzkwMjA0OTJ9.5jQnKxKRJpgVF3EmqzPu8HeHWueMEyCW_WtWG1OJdNI",
  //         },
  //         body: JSON.stringify({
  //           phoneNumber,
  //           password,
  //         }),
  //       }
  //     );

  //     if (!response.ok) {
  //       const errorMessage = await response.text();
  //       alert(`Error: ${errorMessage || "Something went wrong!"}`);
  //       return;
  //     }

  //     // Safely parse JSON only if there is content
  //     const result =
  //       response.headers.get("content-length") > 0 ? await response.json() : {};
  //     console.log("the value of the response is ", response);
  //     navigate("/deleteAccountConfirmation", {
  //       state: {
  //         success: true,
  //         message: "Account successfully deleted",
  //         data: response.data, // Pass any data from the response if needed
  //       },
  //     });

  //     // alert(result.message || "Account verified for deletion.");
  //   } catch (error) {
  //     console.error("An error occurred:", error);
  //     alert("An error occurred while verifying the account. Please try again.");
  //   }
  // };
  return (
    <>
      <Header />
      <div className={styles.container}>
        <form className={styles.form} >
          <h1 className={styles.title}>Delete your account</h1>
          <label className={styles.label} htmlFor="phoneNumber">
            Phone Number
          </label>
          <input
            type="text"
            id="phoneNumber"
            className={styles.input}
            placeholder="4862457935"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
          />

          <label className={styles.label} htmlFor="password">
            Password
          </label>
          <input
            type="password"
            id="password"
            className={styles.input}
            placeholder="••••••••"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />

          <button type="submit" className={styles.button} onClick={handleDeleteAccount}>
            Delete Account
          </button>
        </form>
      </div>
    </>
  );
};

export default DeleteAccount;
