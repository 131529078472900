import React, { useEffect } from "react";
import AuthPage from "./pages/AuthPage";

import HomePage from "./pages/HomePage";
import PadcastPage from "./pages/PodcastPage";
import FriendsPage from "./pages/FriendsPage";
import QuotesPage from "./pages/QuotesPage";
import ProfilePage from "./pages/ProfilePage";
import RatingPage from "./pages/RatingPage";
import PremiumPage from "./pages/PremiumPage";
import About from "./pages/About";
import NotFound from "./pages/NotFound";
import { BrowserRouter,Route, Routes, useNavigate } from "react-router-dom";
import TalkingPage from "./pages/TalkingPage";
import QuoteDetailsPage from "./pages/QuoteDetailsPage";
import PodcastDetails from "./pages/PodcastDetails";
import FriendRequestPage from "./pages/FriendRequestPage";
import { getMessaging, onMessage } from "firebase/messaging";
import FriendsTalkingPage from "./pages/FriendsTalkingPage";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import OtpPage from "./pages/OtpPage";
import ForgotPassPage from "./pages/ForgotPassPage";
import ReferralPage from "./pages/ReferralPage";
import TermsAndConditionsPage from "./pages/TermsAndConditionsPage";
import VideoHomePage from "./pages/VideoHomePage";
import VideoCallPage from "./pages/VideoCallPage";
import DeleteAccount from "./pages/DeleteAccount";
import DeleteAccountConfirmation from "./pages/DeleteAccountConfirmation";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'; // Import default styling
import PsychologistSignupPage from "./pages/PsychologistSignupPage";
import PrivacyPolicyPsychologist from "./pages/PrivacyPolicyPsychologist";





function App() {
  useEffect(() => {
    const messaging = getMessaging();
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker
        .register("/firebase-messaging-sw.js")
        .then((registration) => {
          // console.log("Service Worker registered successfully:", registration);
        })
        .catch((err) => {
          console.error("Service Worker registration failed:", err);
        });
    }
    // Listen for notification clicks when the app is in the background
    navigator.serviceWorker.ready.then((registration) => {
      registration.addEventListener("notificationclick", (event) => {
        event.notification.close(); // Close the notification
      });
    });

    // (Optional) Handle foreground notifications
    onMessage(messaging, (payload) => {
      console.log("Foreground notification received: ", payload);
    });
  }, []);

  return (

    // <BrowserRouter>
    <>
          <ToastContainer /> 

    <Routes>
       
      <Route path="/" element={<HomePage />} />
      <Route path="/podcast" element={<PadcastPage />} />
      <Route path="/friends" element={<FriendsPage />} />
      <Route path="/qoutes" element={<QuotesPage />} />
      <Route path="/profile" element={<ProfilePage />} />
      <Route path="/login" element={<AuthPage />} />
      <Route path="/talking" element={<TalkingPage />} />
      <Route path="/quoteDetails" element={<QuoteDetailsPage />} />
      <Route path="/rating" element={<RatingPage />} />
      <Route path="/premium" element={<PremiumPage />} />
      <Route path="/podcastDetails" element={<PodcastDetails />} />
      <Route path="/about" element={<About />} />
      <Route path="/notFound" element={<NotFound />} />
      <Route path="/friendRequest" element={<FriendRequestPage />} />
      <Route path="/friendsTalking" element={<FriendsTalkingPage />} />
      <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
      <Route path="/otp" element={<OtpPage />} />
      <Route path="/forgotPass" element={<ForgotPassPage />} />
      <Route path="/referral" element={<ReferralPage />} />
      <Route path="/termsAndConditions" element={<TermsAndConditionsPage />} />
      <Route path="/videoHome" element={<VideoHomePage />} />
      <Route path="/videoCall" element={<VideoCallPage />} />
      <Route path="/PsychologistSignupPage" element={<PsychologistSignupPage />} />
      <Route path="/deleteAccount" element={<DeleteAccount />} />
      <Route path="/deleteAccountConfirmation" element={<DeleteAccountConfirmation />} />
      <Route path="/PrivacyPolicyPsychologist" element={<PrivacyPolicyPsychologist />} />
      </Routes>
      </>
    // </BrowserRouter>
  );
}

export default App;
