import Header from "../components/Header";
import styles from "../styles/DeleteAccountConfirmation.module.css";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { ConfirmDeleteAccount } from "../services/Api";
import { ToastContainer, toast } from "react-toastify";

const DeleteAccountConfirmation=()=>{
    const [confirmationText, setConfirmationText] = useState("");
    const navigate = useNavigate();
    const location = useLocation();
    const { success, message, data, error } = location.state || {};
   console.log("the value of the data is ",data)
  const handleDelete = async(e) => {
    e.preventDefault();
    if (confirmationText === "DELETE") {
     
      const response = await ConfirmDeleteAccount({data});
      
        // fetch(`http://localhost:5001/api/users/${data}`, {
        //     method: 'DELETE',
        //     headers: {
        //         'Authorization': 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiI0ODYyNDU3OTM1IiwiaWF0IjoxNzM3OTgxMjYzLCJleHAiOjE3MzkwMjA0OTJ9.5jQnKxKRJpgVF3EmqzPu8HeHWueMEyCW_WtWG1OJdNI'
        //     }
        // })
        // .then(response => {
        //     if (!response.ok) {
        //         throw new Error('Delete failed');
        //     }
        //     navigate("/login")
        //     alert("Account deleted successfully.");
        //     // Add any additional logic after successful deletion (e.g., redirect)
        // })
        // .catch(error => {
        //     console.error('Error:', error);
        //     alert("Failed to delete account. Please try again.");
        // });
        if(response){
          console.log("your account has been deleted ")
          toast.success("your account has been deleted!", {
            position: "top-right",
            autoClose: 3000, // Closes after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          navigate("/login")
        }else{
          console.log("unable to delete the account ");
          toast.success("unable to delete the account!", {
            position: "top-right",
            autoClose: 3000, // Closes after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        
    } else {
        alert("Please type 'DELETE' to confirm.");
    }
};
   return <>
   <Header></Header>
   <div className={styles.container_parent} >
   <div className={styles.container}>
      <h1 className={styles.title}>Deleting account</h1>
      <p className={styles.description}>
        Deleting your account will remove all of your information from our database. This cannot be undone.
      </p>
      <label htmlFor="confirmation" className={styles.label}>
        To confirm this, type “DELETE”
      </label>
      <input
        id="confirmation"
        type="text"
        className={styles.input}
        value={confirmationText}
        onChange={(e) => setConfirmationText(e.target.value)}
        placeholder="Type DELETE here"
      />
      <button className={styles.deleteButton} onClick={handleDelete}>
        Delete account
      </button>
    </div>
    </div>
   </>
}
export default DeleteAccountConfirmation;